import React, { useContext, useState, useEffect, useRef } from 'react'
import { Card, CardContent, Grid, Typography, Box, Chip } from '@material-ui/core'
import {
  components,
  DataFlattener,
  constants,
  useServices
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { ReTriggerSubmissionTranslationContext } from './contexts/ReTriggerSubmissionTranslationContext'
import CngBackdrop from 'src/components/cngcomponents/CngBackDrop';
import { v4 as uuid } from 'uuid'
import { useFormContext } from 'react-hook-form';
import ReTriggerSubmissionButtonComponent from './component/ReTriggerSubmissionButtonComponent'
import ReTriggerSubmissionApiUrls from '../../apiUrls/ReTriggerSubmissionApiUrls'
import UserProfileApiUrls from 'src/apiUrls/UserProfileApiUrls'
import SendIcon from '@material-ui/icons/Send'
import moment from 'moment-timezone'
import Table from 'src/components/aciacehighway/Table'
import { useWatch } from 'react-hook-form'
import TinyChip from '../../components/aciacehighway/TinyChip'
import { getStatusMetadata, DisableSubmitButton } from '../../common/NACommon'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateTimeField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
  table: {
    useFetchCodeMaintenanceLookup,
    DateRangeFilter
  },
} = components

const { filter: { IN, EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyId: 0,
  module: "",
  filterByUserFlag: false,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {

  const { setValue, getValues, reset, watch, trigger } = useFormContext();
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { securedSendRequest } = useServices()
  const row = useRef()

  const { getTranslatedText } = useContext(ReTriggerSubmissionTranslationContext)
  const [loadingState, setLoadingState] = useState(false);

  const [formState, setFormState] = useState(uuid());
  const [tableState, setTableState] = useState(uuid());
  const [tableDetailState, setTableDetailState] = useState(false);
  const [formDetailState, setFormDetailState] = useState(false);

  //dropdown state
  const [menuState, setMenuState] = useState(uuid());
  const [menuDetailState, setMenuDetailState] = useState(false);

  const [lookups, setLookups] = useState(null)

  const module = useWatch({ name: "module" })
  const menu = useWatch({ name: "menu" })

  const namespace = 'RE_TRIGGER_SUBMISSION'

  const moduleCode = {
    ACIHW: 'ACIHW',
    ACEHW: 'ACEHW',
    EHBL: 'EHBL',
    ACIOCN: 'ACIOCN'
  }

  const aciMenuCode = {
    MANIFEST: 'MANIFEST',
    CARGO: 'CARGO',
    WACM: 'WACM'
  }

  const aceMenuCode = {
    MANIFEST: 'MANIFEST',
    SHIPMENT: 'SHIPMENT',
    ACE_INBOND: 'ACE_INBOND',
    INBOND_EVENT: 'INBOND_EVENT',
    INBOND_7512: 'INBOND_7512',
  }

  const ehblMenuCode = {
    HOUSE_BILL: 'HOUSE_BILL',
    CLOSE_MSG: 'CLOSE_MSG',
  }

  const aciOcnMenuCode = {
    A6: 'A6',
    A6A: 'A6A',
    ARRIVAL_MESSAGE: 'AM',
  }

  const [configState, setConfigState] = useState(
    {
      labelModule: getTranslatedText(namespace, 'module'),
      labelMenu: getTranslatedText(namespace, 'menu'),
      labelFromSubmittedDatetime: getTranslatedText(namespace, 'fromSubmittedDatetime'),
      labelToSubmittedDatetime: getTranslatedText(namespace, 'toSubmittedDatetime'),
      labelFromStatus: getTranslatedText(namespace, 'fromStatus'),
      labelToStatus: getTranslatedText(namespace, 'toStatus'),
      labelFromMessageFunction: getTranslatedText(namespace, 'fromMessageFunction'),
      labelToMessageFunction: getTranslatedText(namespace, 'toMessageFunction'),
      labelToAmendmentCode: getTranslatedText(namespace, 'toAmendmentCode'),
      labelToManifestMessageFunction: getTranslatedText(namespace, 'toManifestMessageFunction'),
      labelToManifestAmendmentCode: getTranslatedText(namespace, 'toManifestAmendmentCode'),
      labelFromCreatedDatetime: getTranslatedText(namespace, 'fromCreatedDatetime'),
      labelToCreatedDatetime: getTranslatedText(namespace, 'toCreatedDatetime'),
      labelModeOfSubmission: getTranslatedText(namespace, 'modeOfSubmission'),
      labelUserId: getTranslatedText(namespace, 'userId'),
      labelSearchButton: getTranslatedText(namespace, 'searchButton'),
      labelSubmitSelectedButton: getTranslatedText(namespace, 'submitSelectedButton'),
      labelSubmitAllButton: getTranslatedText(namespace, 'submitAllButton'),
      showToManifestMessageFunction: false,
      showToAmendmentCode: false,
      showToManifestAmendmentCode: false,
      dropDownMenuType: '',
      dropDownStatusCodeType: '',
      dropDownStatusCodeType: '',
      dropDownMessageFunctionCodeType: '',
      dropDownAmendmentCodeType: '',
      dropDownManifestMessageFunctionCodeType: '',
      dropDownManifestAmendmentCodeType: '',
      tableFetchAPIUrl: '',
      submitAllAPIUrl: '',
      submitAPIUrl: '',
      tableSortConfig: {},
    }
  )

  useEffect(() => {
    Promise.all([
      //ACI Highway
      //Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHW_MESSAGE_FUNCTION' }],
        undefined,
        'code'
      ),
      //RNS Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_RNS_STATUS' }],
        undefined,
        'code'
      ),
      //RNS Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACI_HW_IBA_MSG_FUNC' }],
        undefined,
        'code'
      ),

      //ACE Highway
      //Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_MSG_FUNCTION_SINGLE' }],
        undefined,
        'code'
      ),
      //Multi
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_MSG_FUNCTION_MULTI' }],
        undefined,
        'code'
      ),
      //shipment
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_SHIP_SUBM_ACTION' }],
        undefined,
        'code'
      ),
      //inbond
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'MESSAGE_FUNCTION_INBOND' }],
        undefined,
        'code'
      ),
      //ABI inbond
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_ABI_ACTION' }],
        undefined,
        'code'
      ),
      //inbond7512
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_ABI_7512_ACTION' }],
        undefined,
        'code'
      ),
      
      //EHBL
      //Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_HB_STATUS' }],
        undefined,
        'code'
      ),
      //Message Function
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'EHBL_MSG_FUNC_CODE' }],
        undefined,
        'code'
      ),

      //ACI Ocean
      //message function
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{
          field: 'codeType', operator: IN,
          value: 'MESSAGE_FUNCTION'
        }], undefined, 'code'
      ),

      //am status
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{
          field: 'codeType', operator: IN,
          value: 'AM_STATUS'
        }], undefined, 'code'
      )

    ]).then(([aciHwyStatus, aciHwyMessageFunction, aciHwyRNSStatus, aciHwyIbaMessageFunction, 
      aceHwyStatus, aceHwySingleMessageFunction, aceHwyMultiMessageFunction, aceHwyShipmentMessageFunction, aceHwyInbondMessageFunction, aceHwyAbiInbondMessageFunction, aceHwyInbond7512MessageFunction,
      ehblStatus, ehblMessageFunction, aciOcnMessageFunction, aciOcnAMStatus
    ]) => {
      setLookups({ aciHwyStatus, aciHwyMessageFunction, aciHwyRNSStatus, aciHwyIbaMessageFunction, 
        aceHwyStatus, aceHwySingleMessageFunction, aceHwyMultiMessageFunction, aceHwyShipmentMessageFunction, aceHwyInbondMessageFunction, aceHwyAbiInbondMessageFunction, aceHwyInbond7512MessageFunction,
        ehblStatus, ehblMessageFunction, aciOcnMessageFunction, aciOcnAMStatus
      })
    })
  }, [])

  useEffect(() => {
    if (module == moduleCode.ACIHW) {
      configState.dropDownMenuType = 'RE_TRIGGER_SUBM_ACIHW_MENU'
    }

    if (module == moduleCode.ACEHW) {
      configState.dropDownMenuType = 'DB_ADMIN_ACEHW_SUB_MODULE'
    }

    if (module == moduleCode.EHBL) {
      configState.dropDownMenuType = 'DB_ADMIN_EHBL_SUB_MODULE'
    }

    if (module == moduleCode.ACIOCN) {
      configState.dropDownMenuType = 'DB_ADMIN_ACIOCN_SUB_MODULE'
    }
    //refresh form details if module is not empty
    if (module != null && module != undefined && module != '') {
      setValue("menu", '');
      setMenuState(uuid())
      setMenuDetailState(true);
      setFormDetailState(false);
      setTableDetailState(false);
    }

  }, [module]);

  useEffect(() => {
    configState.showToManifestMessageFunction = false;
    if (menu != '' && menu != undefined && menu != '') {

      if (module == moduleCode.ACIHW) {
        aciHighwayFormConfig(menu)
      }
      
      if (module == moduleCode.ACEHW) {
        aceHighwayFormConfig(menu)
      }

      if(module == moduleCode.EHBL) {
        ehblFormConfig(menu)
      }

      if(module == moduleCode.ACIOCN) {
        aciOcnFormConfig(menu)
      }

      setValue("fromSubmittedDatetime", '');
      setValue("toSubmittedDatetime", '');
      setValue("fromStatus", '');
      setValue("toStatus", '');
      setValue("fromMessageFunction", '');
      setValue("toMessageFunction", '');
      setValue("toAmendmentCode", '');
      setValue("toManifestMessageFunction", '');
      setValue("toManifestAmendmentCode", '');
      setValue("fromCreatedDatetime", '');
      setValue("toCreatedDatetime", '');
      setValue("modeOfSubmission", '');
      configState.showToAmendmentCode = false
      configState.showToManifestAmendmentCode = false
      setFormDetailState(true)
      setFormState(uuid())
      setTableDetailState(false);
    }
    
  }, [menu]);

  const aciHighwayFormConfig = (menu) => {

    if (menu == aciMenuCode.MANIFEST) {
      configState.dropDownStatusCodeType = 'ACIHWY_STATUS'
      configState.dropDownMessageFunctionCodeType = 'ACIHW_MESSAGE_FUNCTION'
      configState.dropDownAmendmentCodeType = 'ACIHW_MANIFEST_AMENDMENT_CODE'
      configState.showToManifestMessageFunction = false
    }
    else if (menu == aciMenuCode.CARGO) {
      configState.dropDownStatusCodeType = 'ACIHWY_STATUS'
      configState.dropDownMessageFunctionCodeType = 'ACIHW_MESSAGE_FUNCTION'
      configState.dropDownAmendmentCodeType = 'ACIHW_CARGO_AMENDMENT_CODE'
      configState.dropDownManifestAmendmentCodeType = 'ACIHW_MANIFEST_AMENDMENT_CODE'
      configState.dropDownManifestMessageFunctionCodeType = 'ACIHW_MESSAGE_FUNCTION'
      configState.showToManifestMessageFunction = true
    }
    else if (menu == aciMenuCode.WACM) {
      configState.dropDownStatusCodeType = 'ACIHWY_RNS_STATUS'
      configState.dropDownMessageFunctionCodeType = 'ACI_HW_IBA_MSG_FUNC'
      configState.showToManifestMessageFunction = false
    }
  }

  const aceHighwayFormConfig = (menu) => {

    if (menu == aceMenuCode.MANIFEST) {
      configState.dropDownStatusCodeType = 'ACE_HW_STATUS'
      configState.dropDownMessageFunctionCodeType = 'ACE_HW_MSG_FUNCTION_ALL'
      configState.showToManifestMessageFunction = false
    }
    else if (menu == aceMenuCode.SHIPMENT) {
      configState.dropDownStatusCodeType = 'ACE_HW_STATUS'
      configState.dropDownMessageFunctionCodeType = 'ACE_HW_SHIP_SUBM_ACTION'
      configState.dropDownAmendmentCodeType = 'ACE_HW_SHIPMENT_AMENDMENT_CODE'
      configState.dropDownManifestMessageFunctionCodeType = 'ACE_HW_MSG_FUNCTION_ALL'
      configState.showToManifestMessageFunction = true
    }
    else if (menu == aceMenuCode.ACE_INBOND) {
      configState.dropDownStatusCodeType = 'ACE_HW_STATUS'
      configState.dropDownMessageFunctionCodeType = 'MESSAGE_FUNCTION_INBOND'
      configState.showToManifestMessageFunction = false
    }
    else if (menu == aceMenuCode.INBOND_EVENT) {
      configState.dropDownStatusCodeType = 'ACE_HW_STATUS'
      configState.dropDownMessageFunctionCodeType = 'ACE_HW_ABI_ACTION'
      configState.showToManifestMessageFunction = false
    }
    else if (menu == aceMenuCode.INBOND_7512) {
      configState.dropDownStatusCodeType = 'ACE_HW_STATUS'
      configState.dropDownMessageFunctionCodeType = 'ACE_HW_ABI_7512_ACTION'
      configState.showToManifestMessageFunction = false
    }
  }

  const ehblFormConfig = (menu) => {

    if (menu == ehblMenuCode.HOUSE_BILL) {
      configState.dropDownStatusCodeType = 'EHBL_HB_STATUS'
      configState.dropDownMessageFunctionCodeType = 'EHBL_MSG_FUNC_CODE'
      configState.dropDownAmendmentCodeType = 'EHBL_AMENDMENT_CODE'
      configState.showToManifestMessageFunction = false
    }
    else if (menu == ehblMenuCode.CLOSE_MSG) {
      configState.dropDownStatusCodeType = 'EHBL_HB_STATUS'
      configState.dropDownMessageFunctionCodeType = 'EHBL_MSG_FUNC_CODE'
      configState.dropDownAmendmentCodeType = 'EHBL_AMENDMENT_CODE'
      configState.showToManifestMessageFunction = false
    }

  }

  const aciOcnFormConfig = (menu) => {

    if (menu == aciOcnMenuCode.A6) {
      configState.dropDownStatusCodeType = 'ACIOCN_STATUS'
      configState.dropDownMessageFunctionCodeType = 'MESSAGE_FUNCTION'
      configState.showToManifestMessageFunction = false
    }
    else if (menu == aciOcnMenuCode.A6A) {
      configState.dropDownStatusCodeType = 'ACIOCN_STATUS'
      configState.dropDownMessageFunctionCodeType = 'MESSAGE_FUNCTION'
      configState.showToManifestMessageFunction = false
    }
    else if (menu == aciOcnMenuCode.ARRIVAL_MESSAGE) {
      configState.dropDownStatusCodeType = 'AM_STATUS'
      configState.dropDownMessageFunctionCodeType = ''
      configState.showToManifestMessageFunction = false
    }

  }

  const tableConfig = (partyId) => {
    if (module == moduleCode.ACIHW) {
      aciHighwayTableConfig(partyId);
    }

    if (module == moduleCode.ACEHW) {
      aceHighwayTableConfig(partyId);
    }

    if(module == moduleCode.EHBL) {
      ehblTableConfig(partyId);
    }

    if(module == moduleCode.ACIOCN) {
      aciOcnTableConfig(partyId);
    }
  }

  const aciHighwayTableConfig = (partyid) => {

    if (menu == aciMenuCode.MANIFEST) {
      configState.tableColumns = [
        {
          title: getTranslatedText(namespace, 'tripNumber'),
          field: 'tripNum',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'submittedDate'),
          field: 'submittedDate',
          render: (data) =>
            data.submittedDate &&
            moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'currentStatus'),
          field: 'status',
          render: (data) => getLookupValue('aciHwyStatus', data.status),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'messageFunction'),
          field: 'messageFunction',
          render: (data) => getLookupValue('aciHwyMessageFunction', data.messageFunction),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
          field: 'username',
          filtering: false
        }
      ]

      configState.tableFetchFilters = [
        { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
        { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
        { field: 'status', operator: EQUAL, value: getValues("fromStatus") },
        { field: 'messageFunction', operator: EQUAL, value: getValues("fromMessageFunction") },
        { field: 'partyId', operator: IN, value: partyid }
      ]
      configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.ACI_HIGHWAY_SEARCH_MANIFEST;

      configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.ACI_HIGHWAY_SUBMIT_ALL

      configState.submitAPIUrl = ReTriggerSubmissionApiUrls.ACI_HIGHWAY_SUBMIT

      configState.tableSortConfig = {
        type: 'column',
        defaultField: 'submittedDate',
        defaultDirection: 'DESC'
      }
    }
    else if (menu == aciMenuCode.CARGO) {
      configState.tableColumns = [
        {
          title: getTranslatedText(namespace, 'ccn'),
          field: 'ccn',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'tripNumber'),
          field: 'tripNo',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'submittedDate'),
          field: 'submittedDate',
          render: (data) =>
            data.submittedDate &&
            moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'currentStatus'),
          field: 'status',
          render: (data) => getLookupValue('aciHwyStatus', data.status),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'messageFunction'),
          field: 'messageFunction',
          render: (data) => getLookupValue('aciHwyMessageFunction', data.messageFunction),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
          field: 'username',
          filtering: false
        }
      ]

      configState.tableFetchFilters = [
        { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
        { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
        { field: 'status', operator: EQUAL, value: getValues("fromStatus") },
        { field: 'messageFunction', operator: EQUAL, value: getValues("fromMessageFunction") },
        { field: 'partyId', operator: IN, value: partyid }
      ]
      configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.ACI_HIGHWAY_SEARCH_CARGO;

      configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.ACI_HIGHWAY_SUBMIT_ALL

      configState.submitAPIUrl = ReTriggerSubmissionApiUrls.ACI_HIGHWAY_SUBMIT

      configState.tableSortConfig = {
        type: 'column',
        defaultField: 'tripNo',
        defaultDirection: 'DESC'
      }
    }
    else if (menu == aciMenuCode.WACM) {
      configState.tableColumns = [
        {
          title: getTranslatedText(namespace, 'ccn'),
          field: 'ccn',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'submittedDate'),
          field: 'submittedDate',
          render: (data) =>
            data.submittedDate &&
            moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'currentStatus'),
          field: 'status',
          render: (data) => getLookupValue('aciHwyRNSStatus', data.status),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'messageFunction'),
          field: 'messageFunction',
          render: (data) => getLookupValue('aciHwyIbaMessageFunction', data.messageFunction),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
          field: 'username',
          filtering: false
        }
      ]

      configState.tableFetchFilters = [
        { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
        { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
        { field: 'status', operator: EQUAL, value: getValues("fromStatus") },
        { field: 'messageFunction', operator: EQUAL, value: getValues("fromMessageFunction") },
        { field: 'partyId', operator: IN, value: partyid }
      ]
      configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.ACI_HIGHWAY_SEARCH_ARRIVAL_CERT;

      configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.ACI_HIGHWAY_SUBMIT_ALL

      configState.submitAPIUrl = ReTriggerSubmissionApiUrls.ACI_HIGHWAY_SUBMIT

      configState.tableSortConfig = {
        type: 'column',
        defaultField: 'submittedDate',
        defaultDirection: 'DESC'
      }
    }
  }

  const toMessageFunctionOnChange= () =>{
    let toMessageFunction = getValues('toMessageFunction')
    if((module == moduleCode.ACIHW && toMessageFunction == 'CO') || 
      (module == moduleCode.EHBL && toMessageFunction == '52')
    ){
      configState.showToAmendmentCode = true;
    }else{
      configState.showToAmendmentCode = false;
      setValue('toAmendmentCode','')
    }
  }

  const toManifestMessageFunctionOnChange = () => {
    let toManifestMessageFunction = getValues('toManifestMessageFunction')
    if(module == moduleCode.ACIHW && toManifestMessageFunction == 'CO'){
      configState.showToManifestAmendmentCode = true;
    }else{
      configState.showToManifestAmendmentCode = false;
      setValue('toManifestAmendmentCode','')
    }
  }

  const aceHighwayTableConfig = (partyid) => {

    if (menu == aceMenuCode.MANIFEST) {
      configState.tableColumns = [
        {
          title: getTranslatedText(namespace, 'tripNumber'),
          field: 'tripNo',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'submittedDate'),
          field: 'submittedDate',
          render: (data) =>
            data.submittedDate &&
            moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'currentStatus'),
          field: 'status',
          render: (data) => getLookupValue('aceHwyStatus', data.status),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'messageFunction'),
          field: 'messageFunction',
          render: (data) => data.messageIndicator === 'MULTI' ? getLookupValue('aceHwyMultiMessageFunction', data.messageFunction) : getLookupValue('aceHwySingleMessageFunction', data.messageFunction),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
          field: 'username',
          filtering: false
        }
      ]

      configState.tableFetchFilters = [
        { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
        { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
        { field: 'status', operator: EQUAL, value: getValues("fromStatus") },
        { field: 'messageFunction', operator: EQUAL, value: getValues("fromMessageFunction") },
        { field: 'partyId', operator: IN, value: partyid }
      ]
      configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SEARCH_MANIFEST;

      configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SUBMIT_ALL

      configState.submitAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SUBMIT

      configState.tableSortConfig = {
        type: 'column',
        defaultField: 'submittedDate',
        defaultDirection: 'DESC'
      }
    }
    else if (menu == aceMenuCode.SHIPMENT) {
      configState.tableColumns = [
        {
          title: getTranslatedText(namespace, 'tripNumber'),
          field: 'tripNo',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'scn'),
          field: 'scn',
          filtering: false,
          render: (data) => (
            <Box>
              <Typography component='div' variant='inherit' style={{ display: 'flex' }}>
                {data.masterIndicator && (<TinyChip label={'M'} variant='outlined' style={{ margin: 0, marginRight: 5 }} />)} {data.scn}
              </Typography>
            </Box>
          )
        },
        {
          title: getTranslatedText(namespace, 'submittedDate'),
          field: 'submittedDate',
          render: (data) =>
            data.submittedDate &&
            moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'currentStatus'),
          field: 'status',
          render: (data) => getLookupValue('aceHwyStatus', data.status),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'messageFunction'),
          field: 'messageFunction',
          render: (data) => getLookupValue('aceHwyShipmentMessageFunction', data.messageFunction),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
          field: 'username',
          filtering: false
        }
      ]

      configState.tableFetchFilters = [
        { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
        { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
        { field: 'status', operator: EQUAL, value: getValues("fromStatus") },
        { field: 'messageFunction', operator: EQUAL, value: getValues("fromMessageFunction") },
        { field: 'partyId', operator: IN, value: partyid }
      ]
      configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SEARCH_SHIPMENT;

      configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SUBMIT_ALL

      configState.submitAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SUBMIT

      configState.tableSortConfig = {
        type: 'column',
        defaultField: 'submittedDate',
        defaultDirection: 'DESC'
      }
    }
    else if (menu == aceMenuCode.ACE_INBOND) {
      configState.tableColumns = [
        {
          title: getTranslatedText(namespace, 'inbondNumber'),
          field: 'inbond7512No',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'submittedDate'),
          field: 'submittedDate',
          render: (data) =>
            data.submittedDate &&
            moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'currentStatus'),
          field: 'status',
          render: (data) => getLookupValue('aceHwyStatus', data.status),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'messageFunction'),
          field: 'msgFunction',
          render: (data) => getLookupValue('aceHwyInbondMessageFunction', data.msgFunction),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
          field: 'username',
          filtering: false
        }
      ]

      configState.tableFetchFilters = [
        { field: 'messageCode', operator: 'in', value: ['98', '833'] },
        { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
        { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
        { field: 'status', operator: EQUAL, value: getValues("fromStatus") },
        { field: 'messageFunction', operator: EQUAL, value: getValues("fromMessageFunction") },
        { field: 'partyId', operator: IN, value: partyid }
      ]
      configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SEARCH_INBOND;

      configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SUBMIT_ALL

      configState.submitAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SUBMIT

      configState.tableSortConfig = {
        type: 'column',
        defaultField: 'submittedDate',
        defaultDirection: 'DESC'
      }
    }
    else if (menu == aceMenuCode.INBOND_EVENT) {
      configState.tableColumns = [
        {
          title: getTranslatedText(namespace, 'inbondNumber'),
          field: 'inbond7512No',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'submittedDate'),
          field: 'submittedDate',
          render: (data) =>
            data.submittedDate &&
            moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'currentStatus'),
          field: 'status',
          render: (data) => getLookupValue('aceHwyStatus', data.status),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'messageFunction'),
          field: 'msgFunction',
          render: (data) => getLookupValue('aceHwyAbiInbondMessageFunction', data.msgFunction),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
          field: 'username',
          filtering: false
        }
      ]

      configState.tableFetchFilters = [
        { field: 'messageCode', operator: 'equal', value: 'null' },
        { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
        { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
        { field: 'status', operator: EQUAL, value: getValues("fromStatus") },
        { field: 'messageFunction', operator: EQUAL, value: getValues("fromMessageFunction") },
        { field: 'partyId', operator: IN, value: partyid }
      ]
      configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SEARCH_INBOND;

      configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SUBMIT_ALL

      configState.submitAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SUBMIT

      configState.tableSortConfig = {
        type: 'column',
        defaultField: 'submittedDate',
        defaultDirection: 'DESC'
      }
    }
    else if (menu == aceMenuCode.INBOND_7512) {
      configState.tableColumns = [
        {
          title: getTranslatedText(namespace, 'inbondNumber'),
          field: 'bondNumber',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'submittedDate'),
          field: 'submittedDate',
          render: (data) =>
            data.submittedDate &&
            moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'currentStatus'),
          field: 'status',
          render: (data) => getLookupValue('aceHwyStatus', data.status),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'messageFunction'),
          field: 'actionCode',
          render: (data) => getLookupValue('aceHwyInbond7512MessageFunction', data.actionCode),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
          field: 'username',
          filtering: false
        }
      ]

      configState.tableFetchFilters = [
        { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
        { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
        { field: 'status', operator: EQUAL, value: getValues("fromStatus") },
        { field: 'partyId', operator: IN, value: partyid }
      ]
      configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SEARCH_INBOND_7512;

      configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SUBMIT_ALL

      configState.submitAPIUrl = ReTriggerSubmissionApiUrls.ACE_HIGHWAY_SUBMIT

      configState.tableSortConfig = {
        type: 'column',
        defaultField: 'submittedDate',
        defaultDirection: 'DESC'
      }
    }
  }

  const ehblTableConfig = (partyid) => {
    
    if (menu == ehblMenuCode.HOUSE_BILL) {
      configState.tableColumns = [
        {
          title: getTranslatedText(namespace, 'primaryCcn'),
          field: 'primaryCcn',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'ccn'),
          field: 'ccn',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'submittedDate'),
          field: 'submittedDate',
          render: (data) =>
            data.submittedDate &&
            moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'currentStatus'),
          field: 'status',
          render: (data) => getLookupValue('ehblStatus', data.status),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'messageFunction'),
          field: 'messageFuncCode',
          render: (data) => getLookupValue('ehblMessageFunction', data.messageFuncCode),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
          field: 'username',
          filtering: false
        }
      ]

      configState.tableFetchFilters = [
        { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
        { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
        { field: 'status', operator: EQUAL, value: getValues("fromStatus") },
        { field: 'messageFuncCode', operator: EQUAL, value: getValues("fromMessageFunction") },
        { field: 'partyId', operator: IN, value: partyid }
      ]
      configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.EHBL_SEARCH_HOUSE_BILL;

      configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.EHBL_SUBMIT_ALL

      configState.submitAPIUrl = ReTriggerSubmissionApiUrls.EHBL_SUBMIT

      configState.tableSortConfig = {
        type: 'column',
        defaultField: 'submittedDate',
        defaultDirection: 'DESC'
      }
    }else if (menu == ehblMenuCode.CLOSE_MSG) {
        configState.tableColumns = [
          {
            title: getTranslatedText(namespace, 'previousCcn'),
            field: 'prevCcn',
            filtering: false
          },
          {
            title: getTranslatedText(namespace, 'submittedDate'),
            field: 'submittedDate',
            render: (data) =>
              data.submittedDate &&
              moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
            type: "date",
            filterComponent: DateRangeFilter,
            filtering: false
          },
          {
            title: getTranslatedText(namespace, 'currentStatus'),
            field: 'status',
            render: (data) => getLookupValue('ehblStatus', data.status),
            filtering: false
          },
          {
            title: getTranslatedText(namespace, 'messageFunction'),
            field: 'msgFuncCode',
            render: (data) => getLookupValue('ehblMessageFunction', data.msgFuncCode),
            filtering: false
          },
          {
            title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
            field: 'username',
            filtering: false
          }
        ]
  
        configState.tableFetchFilters = [
          { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
          { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
          { field: 'status', operator: EQUAL, value: getValues("fromStatus") },
          { field: 'msgFuncCode', operator: EQUAL, value: getValues("fromMessageFunction") },
          { field: 'partyId', operator: IN, value: partyid }
        ]
        configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.EHBL_SEARCH_CLOSE_MSG;
  
        configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.EHBL_SUBMIT_ALL
  
        configState.submitAPIUrl = ReTriggerSubmissionApiUrls.EHBL_SUBMIT
  
        configState.tableSortConfig = {
          type: 'column',
          defaultField: 'submittedDate',
          defaultDirection: 'DESC'
        }
      }
  }

  const aciOcnTableConfig = (partyid) => {
    
    if (menu == aciOcnMenuCode.A6) {
      configState.tableColumns = [
        {
          title: getTranslatedText(namespace, 'ccnShort'),
          field: 'convRefno',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'submittedDate'),
          field: 'submittedDate',
          render: (data) =>
            data.submittedDate &&
            moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'currentStatus'),
          field: 'a6aStatus',
          render: (data) => {
            const status = getStatusMetadata(data.a6aStatus)
    
            return (
              <Chip label={status.label} size='small'
                style={{ backgroundColor: status.color, color: status.contrastColor }}
              />
            )
          },
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'messageFunction'),
          field: 'messageFunction',
          render: (data) => getLookupValue('aciOcnMessageFunction', data.messageFunction),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
          field: 'username',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'createdDate'),
          field: 'createdDate',
          render: (data) =>
            data.createdDate &&
            moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'modeOfSubmission'),
          field: 'modeOfSubmission',
          filtering: false
        }
      ]

      configState.tableFetchFilters = [
        { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
        { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
        { field: 'a6aStatus', operator: EQUAL, value: getValues("fromStatus") },
        { field: 'messageFunction', operator: EQUAL, value: getValues("fromMessageFunction") },
        { field: 'partyId', operator: IN, value: partyid },
        { field: 'fromCreatedDatetime', operator: EQUAL, value: getValues("fromCreatedDatetime") },
        { field: 'toCreatedDatetime', operator: EQUAL, value: getValues("toCreatedDatetime") },
        { field: 'modeOfSubmission', operator: EQUAL, value: getValues("modeOfSubmission") }
      ]
      configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.ACI_OCEAN_SEARCH_A6;

      configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.ACI_OCEAN_SUBMIT_ALL

      configState.submitAPIUrl = ReTriggerSubmissionApiUrls.ACI_OCEAN_SUBMIT

      configState.tableSortConfig = {
        type: 'column',
        defaultField: 'createdDate',
        defaultDirection: 'DESC'
      }
    }else if (menu == aciOcnMenuCode.A6A) {
      configState.tableColumns = [
        {
          title: getTranslatedText(namespace, 'ccnShort'),
          field: 'ccn',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'submittedDate'),
          field: 'submittedDate',
          render: (data) =>
            data.submittedDate &&
            moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'currentStatus'),
          field: 'a6aStatus',
          render: (data) => {
            const status = getStatusMetadata(data.a6aStatus)
    
            return (
              <Chip label={status.label} size='small'
                style={{ backgroundColor: status.color, color: status.contrastColor }}
              />
            )
          },
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'messageFunction'),
          field: 'messageFunction',
          render: (data) => getLookupValue('aciOcnMessageFunction', data.messageFunction),
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
          field: 'username',
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'createdDate'),
          field: 'createdDate',
          render: (data) =>
            data.createdDate &&
            moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
          type: "date",
          filterComponent: DateRangeFilter,
          filtering: false
        },
        {
          title: getTranslatedText(namespace, 'modeOfSubmission'),
          field: 'modeOfSubmission',
          filtering: false
        }
      ]
  
        configState.tableFetchFilters = [
          { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
          { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
          { field: 'a6aStatus', operator: EQUAL, value: getValues("fromStatus") },
          { field: 'messageFunction', operator: EQUAL, value: getValues("fromMessageFunction") },
          { field: 'partyId', operator: IN, value: partyid },
          { field: 'fromCreatedDatetime', operator: EQUAL, value: getValues("fromCreatedDatetime") },
          { field: 'toCreatedDatetime', operator: EQUAL, value: getValues("toCreatedDatetime") },
          { field: 'modeOfSubmission', operator: EQUAL, value: getValues("modeOfSubmission") }
        ]
        configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.ACI_OCEAN_SEARCH_A6A;
  
        configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.ACI_OCEAN_SUBMIT_ALL
  
        configState.submitAPIUrl = ReTriggerSubmissionApiUrls.ACI_OCEAN_SUBMIT
  
        configState.tableSortConfig = {
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }
      }else if (menu == aciOcnMenuCode.ARRIVAL_MESSAGE) {
        configState.tableColumns = [
          {
            title: getTranslatedText(namespace, 'convRefNo'),
            field: 'conveyancereferencenumber',
            filtering: false
          },
          {
            title: getTranslatedText(namespace, 'submittedDate'),
            field: 'submissionDateTime',
            render: (data) =>
              data.submissionDateTime &&
              moment(data.submissionDateTime).tz("Canada/Eastern").format('D MMM YYYY'),
            type: "date",
            filterComponent: DateRangeFilter,
            filtering: false
          },
          {
            title: getTranslatedText(namespace, 'currentStatus'),
            field: 'status',
            render: (data) => {
              const status = getStatusMetadata(data.status)

              return (
                <Chip label={status.label} size='small'
                  style={{ backgroundColor: status.color, color: status.contrastColor }}
                />
              )
            },
            filtering: false
            // render: (data) => getLookupValue('aciOcnAMStatus', data.status),
            // filtering: false
          },
          {
            title: getTranslatedText(namespace, 'userIdIncludedChildParty'),
            field: 'username',
            filtering: false
          },
          {
            title: getTranslatedText(namespace, 'createdDate'),
            field: 'createdDate',
            render: (data) =>
              data.createdDate &&
              moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm'),
            type: "date",
            filterComponent: DateRangeFilter,
            filtering: false
          },
          {
            title: getTranslatedText(namespace, 'modeOfSubmission'),
            field: 'modeOfSubmission',
            filtering: false
          }
        ]
    
          configState.tableFetchFilters = [
            { field: 'fromSubmittedDatetime', operator: EQUAL, value: getValues("fromSubmittedDatetime") },
            { field: 'toSubmittedDatetime', operator: EQUAL, value: getValues("toSubmittedDatetime") },
            { field: 'status', operator: EQUAL, value: getValues("fromStatus") },
            { field: 'partyId', operator: IN, value: partyid },
            { field: 'fromCreatedDatetime', operator: EQUAL, value: getValues("fromCreatedDatetime") },
            { field: 'toCreatedDatetime', operator: EQUAL, value: getValues("toCreatedDatetime") },
            { field: 'modeOfSubmission', operator: EQUAL, value: getValues("modeOfSubmission") }
          ]
          configState.tableFetchAPIUrl = ReTriggerSubmissionApiUrls.ACI_OCEAN_SEARCH_ARRIVAL_MESSAGE;
    
          configState.submitAllAPIUrl = ReTriggerSubmissionApiUrls.ACI_OCEAN_SUBMIT_ALL
    
          configState.submitAPIUrl = ReTriggerSubmissionApiUrls.ACI_OCEAN_SUBMIT
    
          configState.tableSortConfig = {
            type: 'column',
            defaultField: 'createdDate',
            defaultDirection: 'DESC'
          }
        }
  }

  //fix  toSubmittedDatetime validation issues if field value is null
  useEffect(()=>{
    if(getValues("toSubmittedDatetime") == null){
      setValue("toSubmittedDatetime",'')
    }
  },[getValues("toSubmittedDatetime")])
  useEffect(() => {
    if (getValues("toCreatedDatetime") == null) {
      setValue("toCreatedDatetime", '')
    }
  }, [getValues("toCreatedDatetime")])

  const onSearch = () => {

    //validate form
    const result = trigger()

    result.then(function (result) {

      let userId = getValues("userId")
      if (result == true && userId) {
        securedSendRequest.execute('POST', UserProfileApiUrls.GET_ALL_CHILD_PARTY_ID_BY_USERNAME, { loginId: userId },
          (response) => {
            console.log("sbPartyIdList: " + JSON.stringify(response.data));

            if(response.data && response.data.length>0){
              tableConfig(response.data)

              //refresh table 
              setTableState(uuid())
              setTableDetailState(true);
            }else{
              showNotification('error', getTranslatedText(namespace, 'userIdNotFound'))
            }
          },
          (error) => {
            console.log("sbPartyIdList Error: " + JSON.stringify(error));
          },
          {},
          {},
          null);
      } else {
        if (result == true) {
          tableConfig(null)

          //refresh table 
          setTableState(uuid())
          setTableDetailState(true);
        }
      }
    })
  }

  const onSubmitAll = () => {
    let submitData = {}

    submitData.module = module
    submitData.menu = menu
    submitData.fromStatus = getValues("fromStatus")
    submitData.toStatus = getValues("toStatus")
    submitData.fromMessageFunction = getValues("fromMessageFunction")
    submitData.toMessageFunction = getValues("toMessageFunction")
    submitData.toAmendmentCode = getValues("toAmendmentCode")
    submitData.toManifestMessageFunction = getValues("toManifestMessageFunction")
    submitData.toManifestAmendmentCode = getValues("toManifestAmendmentCode")
    submitData.fromSubmittedDatetime = getValues("fromSubmittedDatetime")
    submitData.toSubmittedDatetime = getValues("toSubmittedDatetime")
    submitData.fromCreatedDatetime = getValues("fromCreatedDatetime")
    submitData.toCreatedDatetime = getValues("toCreatedDatetime")
    submitData.modeOfSubmission = getValues("modeOfSubmission")

    //validate form
    const result = trigger()

    result.then(function (result) {

      let userId = getValues("userId")
      if (result == true && userId) {
        setLoadingState(true)
        securedSendRequest.execute('POST', UserProfileApiUrls.GET_ALL_CHILD_PARTY_ID_BY_USERNAME, { loginId: userId },
          (response) => {
            console.log("sbPartyIdList: " + JSON.stringify(response.data));
            submitData.partyIdList = response.data

            if(submitData.partyIdList && submitData.partyIdList.length>0){
              submitAll(submitData)
            }else{
              setLoadingState(false)
              showNotification('error', getTranslatedText(namespace, 'userIdNotFound'))
            }

          },
          (error) => {
            console.log("sbPartyIdList Error: " + JSON.stringify(error));
          },
          {},
          {},
          null);
      } else {
        if (result == true) {
          setLoadingState(true)
          submitAll(submitData)

        }
      }
    })
  }

  const submitAll = (submitData) => {
    if ('' == configState.submitAllAPIUrl) {
      showNotification('error', getTranslatedText(namespace, 'emptySubmitMessage'))
      setLoadingState(false)
    } else {
      securedSendRequest.execute('POST', configState.submitAllAPIUrl, submitData,
        (response) => {
          if (response.status == 200) {
            const data = response.data
            if (data.errorMessages != null && data.errorMessages != undefined && data.errorMessages.length > 0) {
              showNotification('error', data.errorMessages)
            } else {
              onSearch()
              showNotification('success', getTranslatedText(namespace, 'submitSuccessMessage'))
            }
          } else {
            showNotification('error', getTranslatedText(namespace, 'submitFailMessage'))
          }
        }
        ,
        (error) => {
          console.error('Error', error)
          showNotification('error', getTranslatedText(namespace, 'submitFailMessage'))
        }
        ,
        (response) => {
          setLoadingState(false)
        }
        ,
        {},
        null);
    }
  }

  const onSubmit = (selectedRows) => {
    let submitData = {}

    submitData.module = module
    submitData.menu = menu
    submitData.fromStatus = getValues("fromStatus")
    submitData.toStatus = getValues("toStatus")
    submitData.fromMessageFunction = getValues("fromMessageFunction")
    submitData.toMessageFunction = getValues("toMessageFunction")
    submitData.toAmendmentCode = getValues("toAmendmentCode")
    submitData.toManifestMessageFunction = getValues("toManifestMessageFunction")
    submitData.toManifestAmendmentCode = getValues("toManifestAmendmentCode")
    submitData.fromSubmittedDatetime = getValues("fromSubmittedDatetime")
    submitData.toSubmittedDatetime = getValues("toSubmittedDatetime")
    submitData.fromCreatedDatetime = getValues("fromCreatedDatetime")
    submitData.toCreatedDatetime = getValues("toCreatedDatetime")
    submitData.modeOfSubmission = getValues("modeOfSubmission")
    submitData.selectedIdList = selectedRows.map(x => x.id)

    //validate form
    const result = trigger()

    result.then(function (result) {

      let userId = getValues("userId")
      if (result == true && userId) {
        setLoadingState(true)
        securedSendRequest.execute('POST', UserProfileApiUrls.GET_ALL_CHILD_PARTY_ID_BY_USERNAME, { loginId: userId },
          (response) => {
            console.log("sbPartyIdList: " + JSON.stringify(response.data));
            submitData.partyIdList = response.data

            if(submitData.partyIdList && submitData.partyIdList.length>0){
              submit(submitData)
            }else{
              setLoadingState(false)
              showNotification('error', getTranslatedText(namespace, 'userIdNotFound'))
            }

          },
          (error) => {
            console.log("sbPartyIdList Error: " + JSON.stringify(error));
          },
          {},
          {},
          null);
      } else {
        if (result == true) {
          setLoadingState(true)
          submit(submitData)
        }
      }
    })
  }


  const submit = (submitData) => {
    securedSendRequest.execute('POST', configState.submitAPIUrl, submitData,
      (response) => {
        if (response.status == 200) {
          const data = response.data
          if (data.errorMessages != null && data.errorMessages != undefined && data.errorMessages.length > 0) {
            showNotification('error', data.errorMessages)
          } else {
            onSearch()
            showNotification('success', getTranslatedText(namespace, 'submitSuccessMessage'))
          }
        } else {
          showNotification('error', getTranslatedText(namespace, 'submitFailMessage'))
        }
      }
      ,
      (error) => {
        console.error('Error', error)
        showNotification('error', getTranslatedText(namespace, 'submitFailMessage'))
      }
      ,
      (response) => {
        setLoadingState(false)
      }
      ,
      {},
      null);
  }

  const onClear = () => {
    reset();
    setFormDetailState(false);
    setTableDetailState(false);
    setMenuDetailState(false);
    setConfigState(
      {
        labelModule: getTranslatedText(namespace, 'module'),
        labelMenu: getTranslatedText(namespace, 'menu'),
        labelFromSubmittedDatetime: getTranslatedText(namespace, 'fromSubmittedDatetime'),
        labelToSubmittedDatetime: getTranslatedText(namespace, 'toSubmittedDatetime'),
        labelFromStatus: getTranslatedText(namespace, 'fromStatus'),
        labelToStatus: getTranslatedText(namespace, 'toStatus'),
        labelFromMessageFunction: getTranslatedText(namespace, 'fromMessageFunction'),
        labelToMessageFunction: getTranslatedText(namespace, 'toMessageFunction'),
        labelToAmendmentCode: getTranslatedText(namespace, 'toAmendmentCode'),
        labelToManifestMessageFunction: getTranslatedText(namespace, 'toManifestMessageFunction'),
        labelToManifestAmendmentCode: getTranslatedText(namespace, 'toManifestAmendmentCode'),
        labelFromCreatedDatetime: getTranslatedText(namespace, 'fromCreatedDatetime'),
        labelToCreatedDatetime: getTranslatedText(namespace, 'toCreatedDatetime'),
        labelModeOfSubmission: getTranslatedText(namespace, 'modeOfSubmission'),
        labelUserId: getTranslatedText(namespace, 'userId'),
        labelSearchButton: getTranslatedText(namespace, 'searchButton'),
        labelSubmitSelectedButton: getTranslatedText(namespace, 'submitSelectedButton'),
        labelSubmitAllButton: getTranslatedText(namespace, 'submitAllButton'),
        showToManifestMessageFunction: false,
        showToAmendmentCode: false,
        showToManifestAmendmentCode: false,
        dropDownMenuType: '',
        dropDownStatusCodeType: '',
        dropDownStatusCodeType: '',
        dropDownMessageFunctionCodeType: '',
        dropDownAmendmentCodeType: '',
        dropDownManifestMessageFunctionCodeType: '',
        dropDownManifestAmendmentCodeType: '',
        tableFetchAPIUrl: '',
        submitAllAPIUrl: '',
        submitAPIUrl: '',
        tableSortConfig: {},
      }
    )
  }

  const getLookupValue = (name, value) => {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  return (
    <Card>
      <CngSimpleCardHeader title={getTranslatedText(namespace, 'reTriggerSubmission')} />
      <CngGridItem xs={12} sm={9}>
        <CngBackdrop loading={loadingState} />
      </CngGridItem>
      <CardContent>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.module}>
            <CngCodeMasterAutocompleteField
              name='module'
              label={configState.labelModule}
              disabled={disabled}
              codeType='RE_TRIGGER_SUBMISSION_MODULE'
              size='small'
              required
            />
          </CngGridItem>
          {menuDetailState &&
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.menu}>
              <CngCodeMasterAutocompleteField
              key={menuState}
                name='menu'
                label={configState.labelMenu}
                disabled={disabled}
                codeType={configState.dropDownMenuType}
                size='small'
                required
              />
            </CngGridItem>
          }
          {formDetailState && <>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fromSubmittedDatetime}>
              <CngDateTimeField
                name="fromSubmittedDatetime"
                label={configState.labelFromSubmittedDatetime}
                format="YYYY-MM-DD HH:mm"
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.toSubmittedDatetime}>
              <CngDateTimeField
                name="toSubmittedDatetime"
                label={configState.labelToSubmittedDatetime}
                format="YYYY-MM-DD HH:mm"
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fromStatus}>
              <CngCodeMasterAutocompleteField
                key={formState}
                name='fromStatus'
                label={configState.labelFromStatus}
                disabled={disabled}
                codeType={configState.dropDownStatusCodeType}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.toStatus}>
              <CngCodeMasterAutocompleteField
                key={formState}
                name='toStatus'
                label={configState.labelToStatus}
                disabled={disabled}
                codeType={configState.dropDownStatusCodeType}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fromMessageFunction}>
              <CngCodeMasterAutocompleteField
                key={formState}
                name='fromMessageFunction'
                label={configState.labelFromMessageFunction}
                disabled={configState.dropDownMessageFunctionCodeType == ""}
                codeType={configState.dropDownMessageFunctionCodeType}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.toMessageFunction}>
              <CngCodeMasterAutocompleteField
                key={formState}
                name='toMessageFunction'
                label={configState.labelToMessageFunction}
                disabled={configState.dropDownMessageFunctionCodeType == ""}
                codeType={configState.dropDownMessageFunctionCodeType}
                size='small'
                onChange={toMessageFunctionOnChange}
              />
            </CngGridItem>
            {configState.showToAmendmentCode && 
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.toAmendmentCode}>
              <CngCodeMasterAutocompleteField
                key={formState}
                name='toAmendmentCode'
                label={configState.labelToAmendmentCode}
                disabled={disabled}
                codeType={configState.dropDownAmendmentCodeType}
                size='small'
              />
            </CngGridItem>
            }
            {formState && configState.showToManifestMessageFunction &&
              <>
               <CngGridItem xs={12} sm={12}>
               <Grid container spacing={1}>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.toManifestMessageFunction}>
                  <CngCodeMasterAutocompleteField
                    name='toManifestMessageFunction'
                    label={configState.labelToManifestMessageFunction}
                    disabled={disabled}
                      codeType={configState.dropDownManifestMessageFunctionCodeType}
                    size='small'
                    onChange={toManifestMessageFunctionOnChange}
                  />
                </CngGridItem>
                {configState.showToManifestAmendmentCode && 
                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.toManifestAmendmentCode}>
                    <CngCodeMasterAutocompleteField
                      key={formState}
                      name='toManifestAmendmentCode'
                      label={configState.labelToManifestAmendmentCode}
                      disabled={disabled}
                      codeType={configState.dropDownManifestAmendmentCodeType}
                      size='small'
                    />
                  </CngGridItem>
                }
                </Grid>
              </CngGridItem>
              </>
            }
            {module == 'ACIOCN' && <>
              <CngGridItem xs={12} sm={12}>
                <Grid container spacing={1}>
                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fromSubmittedDatetime}>
                    <CngDateTimeField
                      name="fromCreatedDatetime"
                      label={configState.labelFromCreatedDatetime}
                      format="YYYY-MM-DD HH:mm"
                      disabled={disabled}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.toSubmittedDatetime}>
                    <CngDateTimeField
                      name="toCreatedDatetime"
                      label={configState.labelToCreatedDatetime}
                      format="YYYY-MM-DD HH:mm"
                      disabled={disabled}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.module}>
                    <CngCodeMasterAutocompleteField
                      name='modeOfSubmission'
                      label={configState.labelModeOfSubmission}
                      disabled={disabled}
                      codeType='MODE_OF_SUBMISSION'
                      size='small'
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>
            </>
            }
            <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.userId}>
              {(module == 'ACIHW' || module == 'ACEHW') && getTranslatedText(namespace, 'upsMessage')}
              <CngTextField
                name='userId'
                label={configState.labelUserId}
                disabled={disabled}
                //onChange={(e) => { trigger('userId') }}
                size='small'
              />
            </CngGridItem>
          </>
          }
        </Grid>
        <Grid>
          <ReTriggerSubmissionButtonComponent
            onSearch={onSearch}
            onSubmitAll={onSubmitAll}
            onClear={onClear}
            formDetailState={formDetailState}
          />
        </Grid>
        {tableDetailState &&
          <Grid>
            <Table
              key={tableState}
              //{...props}
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              columns={configState.tableColumns}
              fetch={{ url: configState.tableFetchAPIUrl }}
              idAccessor='id'
              notification={showNotification}
              fetchFilters={configState.tableFetchFilters}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 500, 1000]
              }}
              checkboxSelection
              selectActions={[
                {
                  label: getTranslatedText(namespace, 'submitSelectedRecordButton'),
                  icon: (props) => <Box> <SendIcon {...props} fontSize="small" /></Box>,
                  onClick: (selectedRows) => {
                    onSubmit(selectedRows)
                  }
                }
              ]}
              sortConfig={configState.tableSortConfig}
            />
          </Grid>
        }
      </CardContent>
    </Card>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  if (localData.filterByUserFlag == "true") {
    localData.filterByUserFlag = true;
  } else {
    localData.filterByUserFlag = false;
  }

  return localData;
}

function toServerDataFormat(localData) {
  if (localData.filterByUserFlag == true) {
    localData.filterByUserFlag = "true";
  } else {
    localData.filterByUserFlag = "false";
  }
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties

import React, { useCallback } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import ReTriggerSubmissionKeys from 'src/constants/locale/key/ReTriggerSubmission'

const ReTriggerSubmissionTranslationContext = React.createContext()

const ReTriggerSubmissionTranslationConsumer = ReTriggerSubmissionTranslationContext.Consumer

const ReTriggerSubmissionProvider = ({ children }) => {
    const { translate } = useTranslation([
        Namespace.RE_TRIGGER_SUBMISSION
    ])

    const translation = {
        RE_TRIGGER_SUBMISSION: {
            reTriggerSubmission: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.TITLE),
            module: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.MODULE),
            menu: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.MENU),
            fromSubmittedDatetime: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.FROM_SUBMITTED_DATETIME),
            toSubmittedDatetime: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.TO_SUBMITTED_DATETIME),
            fromStatus: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.FROM_STATUS),
            toStatus: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.TO_STATUS),
            fromMessageFunction: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.FROM_MESSAGE_FUNCTION),
            toMessageFunction: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.TO_MESSAGE_FUNCTION),
            toAmendmentCode: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.TO_AMENDMENT_CODE),
            toManifestMessageFunction: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.TO_MANIFEST_MESSAGE_FUNCTION),
            toManifestAmendmentCode: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.TO_MANIFEST_AMENDMENT_CODE),
            fromCreatedDatetime: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.FROM_CREATED_DATETIME),
            toCreatedDatetime: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.TO_CREATED_DATETIME),
            modeOfSubmission: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.MODE_OF_SUBMISSION),
            upsMessage: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.UPS_MESSAGE),
            userId: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.USER_ID),
            searchButton: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.SEARCH_BUTTON),
            submitSelectedRecordButton: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.SUBMIT_SELECTED_RECORD_BUTTON),
            submitAllButton: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.SUBMIT_ALL_BUTTON),
            clearButton: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.CLEAR_BUTTON),
            tripNumber: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.TRIP_NUMBER),
            submittedDate: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.SUBMITTED_DATE),
            currentStatus: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.CURRENT_STATUS),
            messageFunction: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.MESSAGE_FUNCTION),
            userIdIncludedChildParty: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.USER_ID_INCLUDED_CHILD_PARTY),
            createdDate: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.CREATED_DATE),
            submitSuccessMessage: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.SUBMIT_SUCCESS_MESSAGE),
            submitFailMessage: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.SUBMIT_FAIL_MESSAGE),
            ccn: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.CCN),
            scn: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.SCN),
            inbondNumber: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.INBOND_NUMBER),
            userIdNotFound: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.USER_ID_NOT_FOUND),
            emptySubmitMessage: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.EMPTY_SUBMIT_MESSAGE),
            primaryCcn: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.PRIMARY_CCN),
            previousCcn: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.PREVIOUS_CCN),
            ccnShort: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.CCN_SHORT),
            convRefNo: translate(Namespace.RE_TRIGGER_SUBMISSION, ReTriggerSubmissionKeys.CONV_REF_NO),
        }
    }

    const getTranslatedText = useCallback((namespace, key) => {
        if (!namespace || !key) return undefined

        return translation?.[namespace]?.[key] || undefined
    }, [])

    return (
        <ReTriggerSubmissionTranslationConsumer.Provider value={{ translation, getTranslatedText }}>
            {children}
        </ReTriggerSubmissionTranslationConsumer.Provider>
    )
}

export default ReTriggerSubmissionProvider

export { ReTriggerSubmissionTranslationContext, ReTriggerSubmissionTranslationConsumer }